<template>
    <InputSwitch v-model="checked" @change="update()"/>
</template>


<script>

import { mapGetters } from "vuex";

export default {
    emits: ['submit'], 
    props: [
        'item',
        'status',
        'url',
        'data'
    ],
    data() {
        return {
            checked : false,
            checkedOld : null
        }
    },
    mounted() {
        this.$store.commit('setErrorEdit', {});

        this.checked = this.status == 'y' ? true : false; 
    },
    watch: {
        status: {
            handler() {
                setTimeout(() => {
                    this.checked = this.status == 'y' ? true : false;
                }, 500);
            },
        },
    },
    computed:{
        ...mapGetters(['errorEdit']),
    },
    methods: {
		update() {

            this.checkedOld = this.checked == false ? true : false;

            // eslint-disable-next-line vue/no-mutating-props
            this.data.beat_outlet_status = this.checked == true ? 'y' : 'n';

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + this.url,
                data: this.data,
            })
            .then((res) => {
                if (res) {
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Data Successfully Saved', life: 3000,});
                    this.$store.commit('setErrors', {});
                    this.$store.commit('setErrorEdit', {});

                    this.$emit('submit');
                }else{
                    this.checked = this.checkedOld;
                }

            })
            .catch((err) => {
                console.log(err);
                this.checked = this.checkedOld;
                this.$store.commit('setErrorEdit', err.response.data.data);
                this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Failed to Saved', life: 3000,});
            });
		},
    }
}
</script>

