<template>
    <div class="p-fluid">
        <div class="formgrid grid">
            <div class="field col-12 md:col-6 lg:col-3">
                <label>Territory</label>
                <Dropdown id="territory_id" dataKey="territory_id" v-model="forms.territory_id" :loading="loadingDropdownTerritory"
                    :options="dataDropdownTerritory" :class="{ 'p-invalid': errorAdd.territory_id }"
                    optionLabel="territory_label" optionValue="territory_id" placeholder="Select Territory" :filter="true"
                    :showClear="true" @filter="searchDropdownTerritory($event, 'add')" @change="changeCity()"
                />
                <small class="p-invalid" style="color: red" v-if="errorAdd.territory_id">{{
                    errorAdd.territory_id[0]
                }}</small>
            </div>
            <div class="field col-12 md:col-6 lg:col-3">
                <label>City</label>
                <Dropdown id="city_id" dataKey="city_id" v-model="forms.city_id" :loading="loadingDropdownCity"
                    :options="dataDropdownCity" :class="{ 'p-invalid': errorAdd.city_id }"
                    optionLabel="label" optionValue="city_id" placeholder="Select City"
                    :filter="true" :showClear="true" @filter="searchDropdownCity($event, 'add')"
                    @change="changeOutlet()" />
                <small class="p-invalid" style="color: red" v-if="errorAdd.city_id">{{
                    errorAdd.city_id[0]
                }}</small>
            </div>
        </div>
        <div class="formgrid grid">
            <div class="field col-12 md:col-6 lg:col-3">
                <label>Outlet</label>
                <div class="field-checkbox mt-2">
                    <Checkbox id="isAllOutlet" v-model="checked" :binary="true" :disabled="forms.city_id == null" @change="this.forms.outlet_id = [];"/>
                    <label for="isAllOutlet">Check if selecting all outlets</label>
                </div>
                <Listbox v-model="forms.outlet_id" :disabled="checked" dataKey="outlet_id" :options="dataDropdownOutlet" :class="{ 'p-invalid': errorAdd.outlet_id }" :multiple="true" :filter="true" optionLabel="outlet_name" optionValue="outlet_id" listStyle="max-height:250px" style="width:15rem" @filter="searchDropdownOutlet($event, 'add')">
                    <template #option="slotProps">
                        <div class="country-item">
                            <div>{{slotProps.option.label}}</div>
                        </div>
                    </template>
                </Listbox>
                <small class="p-invalid" style="color: red" v-if="errorAdd.outlet_id">{{
                    errorAdd.outlet_id[0]
                }}</small>
            </div>
        </div>
    </div>
    <Button :loading="loading" label="Save" icon="pi pi-save" class="p-button-primary" @click="saveNew" />
</template>

<script>

import { mapGetters } from "vuex";

export default {
    emits: ["submit"],
    props: [
		"id",
    ],
    data() {
        return {
            // loading
            loading: false,
            loadingDropdownTerritory: false,
            loadingDropdownCity: false,
            loadingDropdownOutlet: false,

            // dataDropdown,
            dataDropdownTerritory: null,
            dataDropdownCity: null,
            dataDropdownOutlet: null,

            // addNew
            forms: {
                territory_id: null,
                city_id: null,
                outlet_id: []
            },
            checked: false,
        }
    },
    mounted() {
        this.$store.commit('setErrorAdd', {});
        this.searchDropdownTerritory('');
    },
    computed:{
        ...mapGetters(['errorAdd']),
    },
    methods: {
        // DROPDOWN
        searchDropdownTerritory(event, purpose, valueEdit) {
            setTimeout(() => {

                if (valueEdit) {
                    this.$refs.dterritory.filterValue = valueEdit;
                }

                if (purpose == "add") {
                    this.loadingDropdownTerritory = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/master-territory',
                    params: {
                        "search": valueEdit ? valueEdit : event.value,
                    }
                })
                .then(res => {

                    if (purpose == "add") {
                        this.dataDropdownTerritory = res.data.data;
                        this.loadingDropdownTerritory = false;
                    } else if (purpose == null) {
                        this.dataDropdownTerritory = res.data.data;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            }, 250);
        },
        changeCity() {
            this.forms.city_id = null;
            this.dataDropdownCity = null;
            this.searchDropdownCity('');
        },
        searchDropdownCity(event, purpose, valueEdit) {
            setTimeout(() => {
                if(this.forms.territory_id){
                    if (valueEdit) {
                        this.$refs.dcity.filterValue = valueEdit;
                    }

                    if (purpose == "add") {
                        this.loadingDropdownCity = true;
                    }

                    this.axios({
                        method: 'GET',
                        url: process.env.VUE_APP_ROOT_API + 'web/select2/master-city5',
                        params: {
                            "search": valueEdit ? valueEdit : event.value,
                            "territory_id" : this.forms.territory_id
                        }
                    })
                    .then(res => {

                        if (purpose == "add") {
                            this.dataDropdownCity = res.data.data;
                            this.loadingDropdownCity = false;
                        } else if (purpose == null) {
                            this.dataDropdownCity = res.data.data;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
                }
            }, 250);
        },
        changeOutlet() {
            this.checked =  false;
            this.forms.outlet_id = [];
            this.dataDropdownOutlet = null;
            this.searchDropdownOutlet('');
        },
        searchDropdownOutlet(event, purpose, valueEdit) {
            setTimeout(() => {
                if (this.forms.city_id) {

                    if (valueEdit) {
                        this.$refs.doutlet.filterValue = valueEdit;
                    }

                    if (purpose == "add") {
                        this.loadingDropdownOutlet = true;
                    }

                    this.axios({
                        method: 'GET',
                        url: process.env.VUE_APP_ROOT_API + 'web/select2/master-outlet',
                        params: {
                            "search": valueEdit ? valueEdit : event.value,
                            "city_id": this.forms.city_id,
                        }
                    })
                        .then(res => {

                            if (purpose == "add") {
                                this.dataDropdownOutlet = res.data.data;
                                this.loadingDropdownOutlet = false;
                            } else if (purpose == null) {
                                this.dataDropdownOutlet = res.data.data;
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }
            }, 250);
        },
        // ADDNEW
        saveNew() {
            this.loading = true;

            if(this.forms.city_id != null){
                if(this.forms.outlet_id.length == 0 && this.checked == false){
                    alert("Please check or fill in the available outlets.");
                    this.loading = false;
                    return;
                }
            }

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/beat-outlet/create',
                data: {
                    "beat_id" : this.id,
                    "territory_id": this.forms.territory_id,
                    "city_id" : this.forms.city_id,
                    "outlet_id": this.forms.outlet_id,
                    "isAllOutlet": this.checked == true ? 1 : 0
                },
            })
            .then((res) => {
                this.loading = false;
                if (res) {
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Data Successfully Saved', life: 3000,});
                    this.$store.commit('setErrors', {});
                    this.$store.commit('setErrorAdd', {});
                    this.clearForms();
                    
                    this.$emit('submit');
                }
            })
            .catch((err) => {
                console.log(err);
                this.loading = false;
                this.$store.commit('setErrorAdd', err.response.data.data ? err.response.data.data : {});
                this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Failed to Saved', life: 3000,});
            });
        },
        clearForms() {
            this.forms.territory_id = null;
            this.forms.city_id = null;
            this.forms.outlet_id = [];
            this.changeCity();
            this.changeOutlet();
        },
    }
}
</script>